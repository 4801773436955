import {
    SET_BUDGETING,
    SET_ESTIMATED_MONTHLY_COSTS,
    SET_PURCHASE,
    SET_FUTURE_EQUITY,
    SET_MORTGAGE,
    SET_BUDGETING_FOR_SALE,
    SET_MORTGAGE_REFINANCE,
    SET_MORTGAGE_RENEWAL,
    SET_MONTHLY_COSTS,
    SET_MONTHLY_REVENUE
}
from '../types';

export const initialState = {
    budgeting_for_sale: {
        export: false,
        expanded: false,
    },
    budgeting: {
        export: false,
        expanded: false,
    },
    estimated_monthly_costs: {
        export: false,
        expanded: false,
    },
    purchase: {
        export: false,
        expanded: false,
    },
    future_equity: {
        export: false,
        expanded: false,
    },
    mortgage: {
        export: false,
        expanded: false,
    },
    mortgage_refinance: {
        export: false,
        expanded: false,
    },
    mortgage_renewal: {
        export: false,
        expanded: false,
    },
    monthly_costs: {
        export: false,
        expanded: false,
    },
    monthly_revenue: {
        export: false,
        expanded: false,
    },
}

export function setBudgetingForSale(data: any) {
    return {
        type: SET_BUDGETING_FOR_SALE,
        payload: { data },
    };
}

export function setMortgageRefinance(data: any) {
    return {
        type: SET_MORTGAGE_REFINANCE,
        payload: { data },
    };
}

export function setMortgageRenewal(data: any) {
    return {
        type: SET_MORTGAGE_RENEWAL,
        payload: { data },
    };
}

export function setMonthlyCosts(data: any) {
    return {
        type: SET_MONTHLY_COSTS,
        payload: { data },
    };
}

export function setMonthlyRevenue(data: any) {
    return {
        type: SET_MONTHLY_REVENUE,
        payload: { data },
    };
}

export function setBudgeting(data: any) {
    return {
        type: SET_BUDGETING,
        payload: { data },
    };
}

export function setEstimatedMonthlyCosts(data: any) {
    return {
        type: SET_ESTIMATED_MONTHLY_COSTS,
        payload: { data },
    };
}

export function setPurchase(data: any) {
    return {
        type: SET_PURCHASE,
        payload: { data },
    };
}


export function setFutureEquity(data: any) {
    return {
        type: SET_FUTURE_EQUITY,
        payload: { data },
    };
}


export function setMortgage(data: any) {
    return {
        type: SET_MORTGAGE,
        payload: { data },
    };
}

export function exportPdfReducer(state = initialState, action: any = {}) {
    switch(action.type) {
        case "SET_BUDGETING":
            return {
                ...state,   
                budgeting: action.payload.data
            };

        case "SET_ESTIMATED_MONTHLY_COSTS":
            return {
                ...state,   
                estimated_monthly_costs: action.payload.data
            };
        case "SET_PURCHASE":
            return {
                ...state,   
                purchase: action.payload.data
            };
        case "SET_FUTURE_EQUITY":
            return {
                ...state,   
                future_equity: action.payload.data
            };
        case "SET_MORTGAGE":
            return {
                ...state,   
                mortgage: action.payload.data
            };
        case "SET_BUDGETING_FOR_SALE":
            return {
                ...state,   
                budgeting_for_sale: action.payload.data
            };
        case "SET_MORTGAGE_REFINANCE":
            return {
                ...state,   
                mortgage_refinance: action.payload.data
            };
        case "SET_MORTGAGE_RENEWAL":
            return {
                ...state,   
                mortgage_renewal: action.payload.data
            };
        case "SET_MONTHLY_COSTS":
            return {
                ...state,   
                monthly_costs: action.payload.data
            };
        case "SET_MONTHLY_REVENUE":
            return {
                ...state,   
                monthly_revenue: action.payload.data
            };
        default:
            return state;
    }
}
