import { AuthReducer } from "./auth";
import { ContactReducer } from "./contact";
import { CoreReducer } from "./core";
import { activeTabReducer } from "./activeTabTopbar";
import { firstRenderReducer } from "./firstRender";
import { resourceDrawerReducer } from "./resourceDrawer";
import loading from "./loading";
import { RateComparisonReducer } from "./rateComparison";
import { ProductOptionReducer } from "./productOptions";
import { ReferalInvitesReducer } from "./referalInvites";
import { SubscriptionPlansReducer } from "./subscriptionPlans";
import { TrainingResourcesReducer } from "./trainingResources";
import { InsurancePlansReducer } from "./insurancePlans";
import { exportPdfReducer } from "./export";

export const APP_REDUCERS = {
  auth: AuthReducer,
  core: CoreReducer,
  contact: ContactReducer,
  rateComparison: RateComparisonReducer,
  activeTab: activeTabReducer,
  firstRender: firstRenderReducer,
  resourceDrawer: resourceDrawerReducer,
  productOption: ProductOptionReducer,
  referalInvites: ReferalInvitesReducer,
  subscriptionPlans: SubscriptionPlansReducer,
  trainingResources: TrainingResourcesReducer,
  insurancePlans: InsurancePlansReducer,
  exportPdf: exportPdfReducer,
  loading,
};
