import apiRequest from "../../@core/axios";

export const initialState = {
    referalsToInvite: [],
    referalsDetails: {
        current_page: 1,
        data: [],
        empty: true,
        from: 1,
        last_page: 1,
        last_page_url: null,
        next_page_url: null,
        path: "",
        per_page: "5",
        prev_page_url: null,
        to: 5,
        total: 5,
      }
}

export function ReferalInvitesReducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case "SET_REFERALS_DETAILS_UPDATE":
            return {
                ...state,   
                referalsDetails: action.payload
            };

        case "SET_REFERALS_TO_INVITE_ADD":
            return {
                ...state,
                referalsToInvite: [...state.referalsToInvite, action.payload]
            };

        case "SET_REFERALS_TO_INVITE_DELETE":
            return {
                ...state,
                referalsToInvite: state.referalsToInvite.filter(
                    (item: any) => item.email !== action.payload.email
                ),
            };
        case "CLEAR_REFERALS_INVITATIONS":
            return {
                ...state,
                referalsToInvite: []
            };
        case "SET_INVALID_EMAILS":
            let invitations = [...state.referalsToInvite]
            invitations.map((invitation: any) => {
                if(action.payload.invalid_emails && action.payload.invalid_emails.length) {
                    let exist = action.payload.invalid_emails.find((email: any) => email.email === invitation.email)
                    invitation.valid = exist ? 0 : 1;
                    invitation.reason = 'already_registered'
                }else {
                    invitation.valid = 1;
                }
            })
            return {
                ...state,
                referalsToInvite: [...invitations]
            }
        default:
            return state;
    }
}

export function clearInvitations() {
    return {
        type: "CLEAR_REFERALS_INVITATIONS",
        payload: {referalsToInvite: []}
    }
}

export function setInvalidEmails(data: any = []) {
    return {
        type: "SET_INVALID_EMAILS",
        payload: data
    }
}

export function setReferalsDetailsUpdate(data: any = {}) {
    return {
      type: "SET_REFERALS_DETAILS_UPDATE",
      payload: data,
    };
  }

export function setReferalsToInviteAdd(data: any = {}) {
    return {
      type: "SET_REFERALS_TO_INVITE_ADD",
      payload: data,
    };
  }

export function setReferalsToInviteDelete(data: any = {}) {
    return {
      type: "SET_REFERALS_TO_INVITE_DELETE",
      payload: data,
    };
  }

export function sendReferalInvitation(data: any): Promise<any> {
    return apiRequest({
        url: `referrals/invite`,
        method: "POST",
        data
    });
}

export function showReferal(referral_id: any): Promise<any> {
    return apiRequest({
        url: `referrals/${referral_id}`,
        method: "GET",
    });
}

export function getReferalLists(data: any): Promise<any> {
    return apiRequest({
        url: `referrals`,
        method: "GET",
        ...data
    });
}