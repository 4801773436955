import { RESET_STORE } from "../types";
import apiRequest from "../../@core/axios";

// Initial state
export const initialState = {};

export function addMortgageRefinance(strategy_plan_id: string) {
  return apiRequest({
    url: `strategy-plans/${strategy_plan_id}/mortgage-refinances`,
    method: "POST",
  });
}

export function updateMortgageRefinance(
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `mortgage-refinances/${mortgage_refinance_id}/update`,
    method: "POST",
    data,
  });
}

export function duplicateMortgageRefinance(mortgage_refinance_id: string) {
  return apiRequest({
    url: `mortgage-refinances/${mortgage_refinance_id}/duplicate`,
    method: "POST",
  });
}

export function deleteMortgageRefinance(mortgage_refinance_id: string) {
  return apiRequest({
    url: `mortgage-refinances/${mortgage_refinance_id}`,
    method: "DELETE",
  });
}

export function addMortgageProductOptions(strategy_plan_id: string) {
  return apiRequest({
    url: `strategy-plans/${strategy_plan_id}/product-options`,
    method: "POST",
  });
}

export function selectMortgageProductOptions(product_option_id: string) {
  return apiRequest({
    url: `product-options/${product_option_id}/select`,
    method: "POST",
  });
}

export function addMortgageRefinanceItems(
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `mortgage-refinances/${mortgage_refinance_id}/mortgage-refinance-items`,
    method: "POST",
    data,
  });
}

export function updateMortgageRefinanceItems(
  mortgage_refinance_id: string,
  data: any
) {
  return apiRequest({
    url: `mortgage-refinance-items/${mortgage_refinance_id}/update`,
    method: "POST",
    data,
  });
}

export function duplicateMortgageRefinanceItems(mortgage_refinance_id: string) {
  return apiRequest({
    url: `mortgage-refinance-items/${mortgage_refinance_id}/duplicate`,
    method: "POST",
  });
}

export function deleteMortgageRefinanceItem(
  mortgage_refinance_item_id: string
) {
  return apiRequest({
    url: `mortgage-refinance-items/${mortgage_refinance_item_id}`,
    method: "DELETE",
  });
}

export function addProductOptionItems(product_option_id: string, data: any) {
  return apiRequest({
    url: `product-options/${product_option_id}/product-option-items`,
    method: "POST",
    data,
  });
}
export function deleteProductOption(product_option_id: string) {
  return apiRequest({
    url: `product-options/${product_option_id}`,
    method: "DELETE",
  });
}

export function deleteProductOptionItems(product_option_id: string) {
  return apiRequest({
    url: `product-option-items/${product_option_id}`,
    method: "DELETE",
  });
}

export function updateProductOptionItem(
  product_option_item_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `product-option-items/${product_option_item_id}/update`,
    method: "POST",
    data,
  });
}

export function updateMortgageRefinanceAfters(
  mortgage_refinance_after_id: string,
  data: any
): Promise<any> {
  return apiRequest({
    url: `mortgage-refinance-afters/${mortgage_refinance_after_id}/update`,
    method: "POST",
    data,
  });
}

export function getRateForecasts(): Promise<any> {
  return apiRequest({
    url: `/rate-forecasts`,
    method: "GET"
  })
}

export function saveRateForecast(data: any): Promise<any> {
  return apiRequest({
    url: `/rate-forecasts/add`,
    method: "POST",
    data
  })
}

export function updateRateForecast(id:number, data: any): Promise<any> {
  return apiRequest({
    url: `/rate-forecasts/${id}/update`,
    method: "POST",
    data
  })
}

export function ProductOptionReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case RESET_STORE:
      return initialState;
    default:
      return state;
  }
}
