/*
 * General
 */

export const RESET_STORE = "RESET_APP";

/*
 * CORE
 */
export const CORE_SET_FETCHING = "CORE_SET_FETCHING";
export const CORE_SET_EDITING = "CORE_SET_EDITING";
export const CORE_SET_VALIDATION_ERRORS = "CORE_SET_VALIDATION_ERRORS";
export const CORE_SET_CONNECTION_STATUS = "CORE_SET_CONNECTION_STATUS";
export const CORE_SET_SS_MODAL_ENABLED = "CORE_SET_SS_MODAL_ENABLED";
export const CORE_SET_SS_MODAL_SAVE = "CORE_SET_SS_MODAL_SAVE";
export const CORE_SET_PROVINCE_LIST = "CORE_SET_PROVINCE_LIST";
export const CORE_SET_LENDERS_LIST = "CORE_SET_LENDERS_LIST";

/*
 * CONTACT / TEAM MEMBER /StrategySession
 */
export const CONTACT_SET_SS_TEAM_LIST = "CONTACT_SET_SS_TEAM_LIST";
export const CONTACT_SET_SS_TEAM_LIST_UPDATE =
  "CONTACT_SET_SS_TEAM_LIST_UPDATE";
export const CONTACT_SET_SS_TEAM_LIST_DELETE =
  "CONTACT_SET_SS_TEAM_LIST_DELETE";
export const CORE_SET_SS_CLIENT_LIST = "CORE_SET_SS_CLIENT_LIST";
export const CORE_SET_SS_CLIENT_LIST_UPDATE = "CORE_SET_SS_CLIENT_LIST_UPDATE";
export const CORE_SET_SS_CLIENT_LIST_DELETE = "CORE_SET_SS_CLIENT_LIST_DELETE";
export const CORE_SET_SP_CLIENT_LIST = "CORE_SET_SP_CLIENT_LIST";
export const CORE_SET_SP_CLIENT_LIST_UPDATE = "CORE_SET_SP_CLIENT_LIST_UPDATE";
export const CORE_SET_SP_CLIENT_LIST_DELETE = "CORE_SET_SP_CLIENT_LIST_DELETE";
export const CORE_SET_SS_OTHER_CLIENT_LIST = "CORE_SET_SS_OTHER_CLIENT_LIST";
export const CORE_SET_SS_OTHER_CLIENT_LIST_UPDATE =
  "CORE_SET_SS_OTHER_CLIENT_LIST_UPDATE";
export const CORE_SET_SS_OTHER_CLIENT_LIST_DELETE =
  "CORE_SET_SS_OTHER_CLIENT_LIST_DELETE";
export const CORE_SET_ADD_CLIENT_FORM_VISIBILITY =
  "CORE_SET_ADD_CLIENT_FORM_VISIBILITY";

export const CONTACT_SET_TEAM_MEMBERS_LIST = "CONTACT_SET_TEAM_MEMBERS_LIST";
export const CONTACT_TEMP_SS_LIST = "CONTACT_TEMP_SS_LIST";
export const CONTACT_SS_CARDS = "CONTACT_SS_CARDS";
export const CONTACT_ALL_SS_CARDS = "CONTACT_ALL_SS_CARDS";
export const CONTACT_SEARCH_SS_CARDS = "CONTACT_SEARCH_SS_CARDS";
export const CONTACT_ACTIVE_SS_PLAN = "CONTACT_ACTIVE_SS_PLAN";

export const CONTACT_SS_COMPANY="CONTACT_SS_COMPANY";
export const IS_OTHER_CONTACT="IS_OTHER_CONTACT";
export const LOGO="LOGO";
export const THEME_COLOR='THEME_COLOR'

export const RESET_SS_LIST = "RESET_SS_LIST";
/*
 * Auth
 */

export const RESET_AUTH = "RESET_AUTH";
export const SET_AUTH_TOKENS = "SET_AUTH_TOKENS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_REGISTER_DATA = "SET_USER_REGISTER_DATA";

/*
 * Notifications
 */

export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA";

/**
 * Export
 */

export const SET_BUDGETING = "SET_BUDGETING";
export const SET_ESTIMATED_MONTHLY_COSTS = "SET_ESTIMATED_MONTHLY_COSTS";
export const SET_PURCHASE = "SET_PURCHASE";
export const SET_FUTURE_EQUITY = "SET_FUTURE_EQUITY";
export const SET_BUDGETING_FOR_SALE = "SET_BUDGETING_FOR_SALE";
export const SET_MORTGAGE_REFINANCE = "SET_MORTGAGE_REFINANCE";
export const SET_MORTGAGE_RENEWAL = "SET_MORTGAGE_RENEWAL";
export const SET_MONTHLY_COSTS = "SET_MONTHLY_COSTS";
export const SET_MONTHLY_REVENUE = "SET_MONTHLY_REVENUE";
export const SET_MORTGAGE = "SET_MORTGAGE";


/**
 * Rate Comparison
 */
export const SET_DEFAULT_COST_OPTIONS = "SET_DEFAULT_COST_OPTIONS";